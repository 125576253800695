import "reflect-metadata";
import { inject, injectable } from "inversify-props";
import IForgotPassViewModel from "./IForgotPassViewModel";
import ILanguageService from "@/core/services/LanguageService/ILanguageService";
import Session from "@/store/Session";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";
import UIManager from "@/store/UIManager";
import IHttpService from "@/core/services/HttpService/IHttpService";
import IAlertDataFactory from "@/core/factories/AlertDataFactory/IAlertDataFactory";
import IBasicResponse from "@/core/models/FunctionData/ResponseData/IBasicResponse";

@injectable()
export default class ForgotPassViewModel implements IForgotPassViewModel {
  private readonly _languageService: ILanguageService;
  private readonly _httpService: IHttpService;
  private readonly _alertDataFactory: IAlertDataFactory;
  private email = "";

  public constructor(
    @inject("AlertDataFactory") alertDataFactory: IAlertDataFactory,
    @inject("HttpService") httpService: IHttpService,
    @inject("LanguageService") languageService: ILanguageService
  ) {
    this._languageService = languageService;
    this._httpService = httpService;
    this._alertDataFactory = alertDataFactory;
  }

  public get ForgotPassCardTitle(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.FORGOT_PASSWORD);
  }

  public get EmailLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.EMAIL);
  }

  public get SubmitLabel(): string {
    return this._languageService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.SUBMIT);
  }

  public get Email(): string {
    return this.email;
  }

  public set Email(value: string) {
    this.email = value;
  }

  public async Submit(): Promise<void> {
    UIManager.ToggleLoading();
    const result = await this._httpService.RequestPost<
      string,
      { email: string }
    >("/requestResetPass", { email: this.email });
    if (result.toString().includes("ERROR")) {
      UIManager.ToggleLoading();
      UIManager.AddAlert(
        this._alertDataFactory.CreateAlertData(
          "mdi-account-lock",
          "accent",
          AllTranslationKeys.USER_NOT_FOUND
        )
      );
    } else {
      UIManager.ToggleLoading();
      UIManager.AddAlert(
        this._alertDataFactory.CreateAlertData(
          "mdi-email-alert",
          "secondary",
          AllTranslationKeys.RESET_EMAIL_SENT
        )
      );
    }
  }
}
