<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="9"
            ><p class="text-subtitle mt-3">
              {{ viewModel.ForgotPassCardTitle }}
            </p></v-col
          >
          <v-col cols="3">
            <v-btn class="bg-primary" icon @click="$router.push({ path: `/` })">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-text-field
          class="mt-3"
          rounded
          :label="viewModel.EmailLabel"
          variant="outlined"
          :model-value="viewModel.Email"
          @update:model-value="(e: string) => viewModel.Email = e"
        ></v-text-field>
        <v-card-actions>
          <v-row justify="end">
            <v-col cols="4">
              <v-btn round class="bg-primary" @click="viewModel.Submit()">{{
                viewModel.SubmitLabel
              }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import IForgotPassViewModel from "@/core/viewModels/ForgotPassViewModel/IForgotPassViewModel";
import { Vue, Options } from "vue-class-component";
import { InjectReactive } from "vue-property-decorator";

@Options({})
export default class ForgotPassView extends Vue {
  @InjectReactive("ForgotPassViewModel")
  private readonly viewModel!: IForgotPassViewModel;
}
</script>
<style scoped></style>
