export const EnglishTranslations = {
  EMAIL: "Email",
  PASS: "Password",
  ERROR_EMAIL_INCORRECT_FORMAT: "Your email is not a valid email address.",
  SERVER_ERROR_UNKNOWN: "Unknown error from server. Contact support.",
  ERROR_SERVER_LOGIN: "Email and/or Password incorrect.",
  TITLE_BASIC_LOGIN: "Log in",
  SUBMIT: "Submit",
  SIGN_UP: "Sign Up",
  NO_CAMPAIGNS: "Looks like you have no campaigns available.",
  TRY_AGAIN: "Try again",
  ERROR_SERVER_MUST_HAVE_BODY: "The request did not have a body.",
  ERROR_SERVER_UNAUTHORIZED: "You are not authorized",
  ERROR_CLIENT_FIREBASE_TOKEN_ERROR:
    "Error trying to recieve a token from firebase",
  ERROR_CLIENT_FIREBASE_TOKEN_NOT_GIVEN:
    "No token was recieved from firebase, no error",
  ERROR_SERVER_HEX_OWNED_BY_ATTACKER: "You already control this hex",
  ERROR_SERVER_ATTACKER_HAS_NO_NEIGHBOR:
    "Your hex does not neighbor the attacked hex",
  ERROR_SERVER_HEX_EXISTING_UNRESOLVED_ACTION:
    "This hex is locked until a pending game action is resolved.",
  ERROR_SERVER_HEX_NOT_ON_CAMPAIGN: "How are you even attacking this hex?",
  ERROR_SERVER_HEX_NOT_OWNED: "Hex not owned",
  ERROR_SERVER_USER_HAS_2_ATTACKS_THIS_TURN:
    "You alrady have two attack actions this turn.",
  ERROR_SERVER_USER_NOT_IN_CAMPAIGN: "How are you attacking this hex?",
  SERVER_SUCCESS_ATTACK: "Attack successful! Check your events page",
  SERVER_SUCCESS_BUILD: "Build success!",
  SERVER_ERROR_CANNOT_BUILD_NOT_YOUR_HEX: "You do not own this hex!",
  ERROR_SERVER_NO_ARMY_FOR_CAMPAIGN:
    "You do not have an army for this campaign.",
  ERROR_SERVER_NO_SUCH_STRUCT:
    "The structure you are trying to build does not exist.",
  ERROR_SERVER_CANNOT_AFFORD:
    "You do not have enough resources to build this structure.",
  ERROR_SERVER_HEX_NOT_OWNED_BUILDER:
    "You do not own this hex, so you cannot build on it.",
  SUCCESS_SERVER_BUILD: "You have successfully built on this hex!",
  CHANGE_PICTURE_LABEL: "Change Profile Picture",
  CHANGE_USERNAME_LABEL: "Change Username",
  CHANGE_EMAIL_LABEL: "Change Email",
  CHANGE_PASS_LABEL: "Change Password",
  USERNAME: "Username",
  SUCCESS_USER_CHANGE: "Successfully updated user!",
  ERROR_SERVER_FAILED_USER_UPDATE:
    "Failed to update user information. Please try again.",
  ERROR_SERVER_PASSWORD_MUST_HAVE_CAPITAL:
    "Password must contain at least one uppercase letter.",
  ERROR_SERVER_PASSWORD_MUST_HAVE_LOWERCASE:
    "Password must contain at least one lowercase letter.",
  ERROR_SERVER_PASSWORD_MUST_HAVE_NUMBERS:
    "Password must contain at least one number.",
  ERROR_SERVER_PASSWORD_MUST_HAVE_SPECIAL_CHARACTERS:
    "Password must contain at least one special character.",
  ERROR_SERVER_PASSWORD_LENGTH: "Password must be between 8 and 20 characters.",
  NO_EVENTS: "Looks like there are no events for this hex.",
  ATTACK_LABEL: "ATTACK",
  BUILD_LABEL: "BUILD",
  ON_LABEL: "On",
  ATTACKED_LABEL: "attacked",
  EVENT_IS_LABEL: "This event is",
  RESOLVED: "RESOLVED",
  ACTIVE: "ACTIVE",
  NEXT_PAGE: "Next Page",
  HEX_HISTORY_LABEL: "Hex History",
  FORGOT_PASSWORD: "Forgot Password",
  USER_NOT_FOUND: "A user with that email was not found!",
  RESET_EMAIL_SENT: "A reset email has been sent!",
  RESET_PASSWORD: "Reset Password",
  ERROR_SERVER_USER_NOT_FOUND: "User not found!",
  SUCCESS_SERVER_RESET_PASSWORD: "Your password has been reset",
};
