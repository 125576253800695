<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="9">
            <p class="text-subtitle mt-3">{{ viewModel.ResetPassTitle }}</p>
          </v-col>
          <v-col cols="3">
            <v-btn class="bg-primary" icon @click="$router.push({ path: `/` })">
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-text-field
          :label="viewModel.PasswordLabel"
          @update:model-value="(e: string) => viewModel.Password = e"
          :model-value="viewModel.Password"
          class="mt-3"
          rounded
          variant="outlined"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end">
          <v-col cols="4">
            <v-btn round class="bg-primary" @click="viewModel.Submit()">{{
              viewModel.SubmitLabel
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import IResetForgottenPassViewModel from "@/core/viewModels/ResetForgottenPassViewModel/IResetForgottenPassViewModel";
import { Vue, Options } from "vue-class-component";
import { InjectReactive } from "vue-property-decorator";

@Options({})
export default class ResetForgottenPassView extends Vue {
  @InjectReactive("ResetForgottenPassViewModel")
  private readonly viewModel!: IResetForgottenPassViewModel;

  public mounted() {
    this.viewModel.MountSpecificCode(this.$route.params.code.toString() || "");
  }
}
</script>
<style scoped></style>
