import { injectable, inject } from "inversify-props";
import "reflect-metadata";
import IResetForgottenPassViewModel from "./IResetForgottenPassViewModel";
import IHttpService from "@/core/services/HttpService/IHttpService";
import ILanguageService from "@/core/services/LanguageService/ILanguageService";
import IAlertDataFactory from "@/core/factories/AlertDataFactory/IAlertDataFactory";
import Session from "@/store/Session";
import { AllTranslationKeys } from "@/core/constants/AllTranslationKeys";
import UIManager from "@/store/UIManager";
import IBasicResponse from "@/core/models/FunctionData/ResponseData/IBasicResponse";
import router from "@/router";

@injectable()
export default class ResetForgottenPassViewModel
  implements IResetForgottenPassViewModel
{
  private readonly _httpService: IHttpService;
  private readonly _langService: ILanguageService;
  private readonly _alertDataFactory: IAlertDataFactory;

  private password = "";
  private code = "";

  public constructor(
    @inject("HttpService") httpService: IHttpService,
    @inject("LanguageService") langService: ILanguageService,
    @inject("AlertDataFactory") alertDataFactory: IAlertDataFactory
  ) {
    this._alertDataFactory = alertDataFactory;
    this._langService = langService;
    this._httpService = httpService;
  }

  public get ResetPassTitle(): string {
    return this._langService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.RESET_PASSWORD);
  }

  public get PasswordLabel(): string {
    return this._langService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.PASS);
  }

  public get SubmitLabel(): string {
    return this._langService
      .SetLangauge(Session.Langauge)
      .GetTranslation(AllTranslationKeys.SUBMIT);
  }

  public get Password(): string {
    return this.password;
  }

  public set Password(value: string) {
    this.password = value;
  }

  public async Submit(): Promise<void> {
    UIManager.ToggleLoading();
    const res = await this._httpService
      .SetInstance("core")
      .RequestPatch<IBasicResponse<string>, { code: string; password: string }>(
        "/resetPassword",
        { code: this.code, password: this.password }
      );
    if ((res as IBasicResponse<string>).toString().includes("ERROR")) {
      UIManager.ToggleLoading();
      UIManager.AddAlert(
        this._alertDataFactory.CreateAlertData(
          "mdi-lock-alert-outline",
          "accent",
          AllTranslationKeys[
            (res as IBasicResponse<string>).toString() as keyof object
          ] ?? AllTranslationKeys.SERVER_ERROR_UNKNOWN
        )
      );
    } else {
      const key = (res as IBasicResponse<string>).document;
      UIManager.ToggleLoading();
      UIManager.AddAlert(
        this._alertDataFactory.CreateAlertData(
          "mdi-lock-check-outline",
          "secondary",
          AllTranslationKeys[key as keyof object] ??
            AllTranslationKeys.SUCCESS_SERVER_RESET_PASSWORD
        )
      );
      router.push({ path: "/" });
    }
  }

  public MountSpecificCode(code: string): void {
    this.code = code;
  }
}
